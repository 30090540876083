import * as d3 from "d3";
import * as crossfilter from "crossfilter2";
import * as cmk_figures from "cmk_figures";
import * as ntop_utils from "ntop_utils";

export class FlowsDashlet extends cmk_figures.FigureBase {
    constructor(div_selector) {
        super(div_selector);
        this._post_url = "ajax_ntop_flows.py";
        this._default_params_dict = {};
    }

    initialize() {
        this._div_selection.classed("ntop_flows", true);
        this._crossfilter = new crossfilter.default();
        this._dimension = this._crossfilter.dimension(d => d);

        let url_params = new URLSearchParams(this._default_params_dict);
        this._post_body = url_params.toString();
        this._filter_choices = {applications: [], categories: [], protocols: []};
        this._setup_dc_table(this._div_selection);
        this._setup_data_update();
        this._div_selection.classed(ntop_utils.ifid_dep, true);
    }

    set_ifid(ifid) {
        this._ifid = ifid;
        this._update_post_body_and_force_update();
    }

    _setup_dc_table(selection) {
        let div_id = "flows_dashlet";
        selection.append("div").attr("id", div_id);
        let cmk_dc_table_figure_class = cmk_figures.figure_registry.get_figure("dc_table");

        this._dc_table = new cmk_dc_table_figure_class("#" + div_id, "flows");

        // WIP: add as first element before paging
        this._setup_fetch_filters();
        this._dc_table.crossfilter(this._crossfilter);
        this._dc_table.dimension(this._dimension);
        this._dc_table.columns(this._get_columns());
        this._dc_table.sort_by(d => d.date);
        this._dc_table.initialize();
        this._dc_table.get_dc_chart().on("renderlet", chart => this._update_css_classes(chart));
    }

    _update_css_classes(chart) {
        chart.selectAll("tr").selectAll("a").classed("ntop_link", true).attr("target", "_blank");
        chart.select("thead tr").classed("header", true);

        let div_bar = chart
            .selectAll("tr")
            .selectAll("div.progress")
            .classed("progress", false)
            .classed("breakdown_bar", true);
        div_bar
            .select("div.bg-warning")
            .classed("progress-bar", false)
            .classed("bg-warning", false)
            .classed("bytes_sent", true);
        div_bar
            .select("div.bg-info")
            .classed("progress-bar", false)
            .classed("bg-info", false)
            .classed("bytes_rcvd", true);

        let columns = this._get_columns();
        chart
            .selectAll("tr")
            .selectAll("td")
            .each((d, idx, nodes) => {
                let classes = columns[idx].classes;
                if (!classes) return;
                let node = d3.select(nodes[idx]);
                classes.forEach(classname => {
                    node.classed(classname, true);
                });
            });
    }

    _setup_data_update() {
        this.set_post_url_and_body(this._post_url, this._post_body);
        this.scheduler.enable();
        this.scheduler.set_update_interval(600);
    }

    _update_filter_choices(filter_choices) {
        this._filter_choices = filter_choices;
        this._setup_fetch_filters();
    }

    _setup_fetch_filters() {
        let filter_divs = this._dc_table._div_options
            .selectAll("div.filters")
            .data([null])
            .join("div")
            .classed("filters", true);

        let filter_div = filter_divs
            .selectAll("div.filter")
            .data(this._filter_choices)
            .join(enter =>
                enter
                    .append("div")
                    .attr("class", d => d.group.toLowerCase())
                    .classed("filter", true)
            );

        filter_div
            .selectAll("label")
            .data(d => [d])
            .join("label")
            .text(d => d.group);
        let select = filter_div
            .selectAll("select")
            .data(d => [d])
            .join("select")
            .attr("url_param", d => d.url_param)
            .classed("filter", true)
            .on("change", () => this._update_post_body_and_force_update());

        select
            .selectAll("option")
            .data(
                d => d.choices,
                d => d.id
            )
            .join(enter => enter.append("option").property("value", d => "" + d.id))
            .text(d => d.name);
    }

    _get_columns() {
        return [
            {
                label: "",
                format: d => d.url_key,
                classes: ["key"],
            },
            {
                label: "Application",
                format: d => d.url_ndpi,
                classes: ["application"],
            },
            {
                label: "Protocol",
                format: d => d.protocol,
                classes: ["protocol"],
            },
            {
                label: "Client",
                format: d => d.url_client,
                classes: ["client"],
            },
            {
                label: "Server",
                format: d => d.url_server,
                classes: ["server"],
            },
            {
                label: "Duration",
                format: d => d.duration,
                classes: ["duration", "number"],
            },
            {
                label: "Score",
                format: d => d.score,
                classes: ["score", "number"],
            },
            {
                label: "Breakdown",
                format: d => d.breakdown,
                classes: ["breakdown"],
            },
            {
                label: "Actual Thpt",
                format: d => d.throughput,
                classes: ["throughput", "number"],
            },
            {
                label: "Total Bytes",
                format: d => d.bytes,
                classes: ["bytes", "number"],
            },
        ];
    }

    _update_post_body_and_force_update() {
        // add default parameters
        let params = Object.assign({}, this._default_params_dict, {ifid: this._ifid});
        // add filter parameters
        this._div_selection.selectAll("select.filter").each((d, idx, nodes) => {
            let select = d3.select(nodes[idx]);
            let key = select.datum().url_param;
            let value = select.property("value");
            if (value == -1) return;
            params = Object.assign(params, {[key]: value});
        });
        let url_params = new URLSearchParams(params);
        this._post_body = url_params.toString();
        this._setup_data_update();
        this.scheduler.force_update();
    }

    update_data(data) {
        // Remove old data
        this._crossfilter.remove(() => true);

        // Add new data
        this._crossfilter.add(data.flows);

        // Update filters
        this._update_filter_choices(data.filter_choices);

        // Update table
        this._dc_table.process_data(data.flows);
    }
}
